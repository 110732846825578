import * as React from 'react';
import { Formatter } from '../../../Shared/utils/formatter';

import * as styles from './amount-display.less';
import { amountToParts as sharedAmountToParts } from '../../utils/helper-utils';

export interface IAmountDisplayProps {
	amount: number;
	precision?: number;
	currencySymbol?: string;
	currencyCode?: string;
}

export class AmountDisplay extends React.Component<IAmountDisplayProps> {
	default = {
		precision: 2,
	};

	render() {
		const {
			amount,
			precision = 2,
			currencySymbol = '$',
		} = this.props;
		const displayValue = Formatter.formatNumberForDisplay(amount, precision);
		const { major, minor } = sharedAmountToParts(amount, precision);

		return (
			<div className={styles.container}>
				<div className={styles.currencySymbol}>{currencySymbol}</div>
				<div className={`${styles.major} ${getSizeName(displayValue)}`}>{major}
					{(precision > 0)
						? <span>.{minor}</span>
						: null
					}
				</div>
			</div>
		);
	}
}

export function getSizeName(value: string): string {
	const size = value.length;
	if (size > 11) {
		return styles.small;
	} else if (size > 8) {
		return styles.medium;
	} else {
		return styles.large;
	}
}
