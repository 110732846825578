import { CustomFieldEditModel, ICustomFieldViewModel, CustomFieldType, FundCustomFieldViewModel } from '../webgiving-generated';

export function getSelectedFundOption(selectedFieldValues: CustomFieldEditModel[], referenceFields: ICustomFieldViewModel[]) {
	const [selectedFund] = selectedFieldValues.filter(x => x.Type === CustomFieldType.Fund);
	if (!selectedFund) {
		return null;
	}
	const [fundField] = referenceFields.filter(x => x.Key === selectedFund.Key);
	if (!fundField) {
		return null;
	}
	const [fundOption] = (fundField as FundCustomFieldViewModel).Options.filter(x => x.Value === selectedFund.Value);
	return fundOption;
}

export function getSelectedFundName(selectedFieldValues: CustomFieldEditModel[], referenceFields: ICustomFieldViewModel[]) {
	const selectedFundOption = getSelectedFundOption(selectedFieldValues, referenceFields);
	return selectedFundOption ? selectedFundOption.Label : '';
}

export function getSelectedFundKey(selectedFieldValues: CustomFieldEditModel[], referenceFields: ICustomFieldViewModel[]) {
	const selectedFundOption = getSelectedFundOption(selectedFieldValues, referenceFields);
	return selectedFundOption ? selectedFundOption.Value : '';
}

export function getFundName(fundKey: string, customFields: ICustomFieldViewModel[] | null) {
	if (!customFields) {
		return '';
	}
	const [customFundField] = customFields.filter(x => x.Type === CustomFieldType.Fund);
	const fundOptions = (customFundField as FundCustomFieldViewModel).Options;
	const fundOption = fundOptions.find(x => x.Value === fundKey);
	return fundOption ? fundOption.Label : '';
}